<script setup>
import { ref } from 'vue'
import markdownParser from "@nuxt/content/transformers/markdown";
let {data: intro} = await useAsyncData('intro_content', () => 
queryContent('/intro').findOne())
let dat = ref(intro)
try {
dat.value.body = await markdownParser.parse("intro-body0-dfjsd",dat.value.body);
} catch(e) {
}
</script>
<template>
  <div class="flex flex-col gap-[30px] md:gap-[42px] pt-[4.5em] md:pt-[6em]">
    <header class="header custom-container">
      <Topnav />
    </header>
    <h1 class="z-10 font-cartridgeRough text-[24px] md:text-[32px] leading-none">{{ dat.title }}</h1>
    <h2 class="z-10 font-cartridgeRough text-[44px] md:text-[96px] leading-none">Trade smarter.</h2>
    <ContentRendererMarkdown :value="dat.body" class="z-10 font-brandonText text-xs max-md:pb-7 w-[100%] md:w-[72%] lg:w-[80%]"/>
    <div class="flex flex-col gap-[16px] md:gap-[20px]">
      <NuxtLink href="https://trading.def.cafe/" alt="Fast Trading Terminal link." target="blank" class="z-10 bg-mainbg button-intro text-[16px] cursor-pointer select-none flex flex-row items-center gap-[12px] border-[1px] w-fit p-[8px] shadow-[2px_4px_0px_rgb(0,0,0)] hover:shadow-none hover:translate-y-[4px] hover:translate-x-[2px]">
        <span>TRY NOW</span>
        <nuxt-img src="/source/strelka.svg" width="24" height="24" class="h-[24px] aspect-auto object-contain" alt="Try now arrow image." />
      </NuxtLink>
    </div>
    <nuxt-img
      src="/source/intro-1.svg" 
            width="320" 
      height="291"
      sizes="320px, md:722px"
      class="absolute z-0 bottom-10 max-md:w-[320px] max-md:h-[291px] right-0 md:top-40 aspect-auto md:w-[722px] md:h-[657px]" 
      alt="Crypto trading bot chessboard image." 
    />
  </div>
</template>
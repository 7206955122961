<script setup>
const {data: topNav} = await useAsyncData('header_nav', () => 
queryContent('/header').findOne())
</script>
<template>
  <nav class="topnav-holder">
    <div class="topnav-element">
      <div class="topnav-button">
        <span class="topnav-header">EARN MORE</span>
        <nuxt-img src="/source/expand.svg" width="8" height="14" class="topnav-arrow" alt="Expand icon." />
      </div>
      <ul>
        <li v-for="navItem of topNav.earn_entry" :key="navItem.link">
          <NuxtLink :to="navItem.link" :target="navItem.link.includes('http')?'_blank':'_self'" class="sidebar-link" >{{ navItem.title }}</NuxtLink>
        </li>
      </ul>
    </div>
    <div class="topnav-element">
      <div class="topnav-button">
        <span class="topnav-header">LEARN MORE</span>
        <nuxt-img src="/source/expand.svg" width="8" height="14" class="topnav-arrow" alt="Expand icon."/>
      </div>
      <ul>
        <li v-for="navItem of topNav.learn_entry" :key="navItem.link">
          <NuxtLink :to="navItem.link" :target="navItem.link.includes('http')?'_blank':'_self'" class="sidebar-link" >{{ navItem.title }}</NuxtLink>
        </li>
      </ul>
    </div>
  </nav>
</template>
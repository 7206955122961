<script setup>
import { defineAsyncComponent } from 'vue';

import Intro from '../components/Intro.vue';
const WhyUse = defineAsyncComponent(() => import('../components/WhyUse.vue'));
const Referral = defineAsyncComponent(() => import('../components/Referral.vue'));
const Staking = defineAsyncComponent(() => import('../components/Staking.vue'));
const Fees = defineAsyncComponent(() => import('../components/Fees.vue'));
const SocialLinks = defineAsyncComponent(() => import('../components/SocialLinks.vue'));

</script>

<template>
  <div class="app-holder">
    <div id="intro" class="custom-container ">
      <Intro />
    </div>
    <WhyUse />
    <Referral />
    <Staking />
    <div class="relative">
      <Fees />
      <SocialLinks />
      <nuxt-img src="/source/stripe.svg"  width="512" height="512" :alt="'Stripes background image'" class="absolute z-[-10] pointer-events-none  w-full max-w-[1000px] overflow-clip object-cover left-[20%] bottom-0"/>
    </div>
    <footer class="fixed bottom-0 left-0">
      <div class="relative">
        <nuxt-img src="/source/bottom-powered.svg" width="160" height="46" alt="Powered by 0xDEFCAFE image."/>
        <span class="absolute top-[12px] left-[11px] font-cartridgeRough text-[13px]">Powered by DEF.CAFE</span>
      </div>
    </footer>
  </div>
</template>